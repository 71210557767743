import { Component, OnInit ,DoCheck } from '@angular/core';
import { Router } from '@angular/router';
// import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ConnetmetamaskService } from '../../core/services/metamask/connetmetamask.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { ConnectMetaMaskComponent } from '../connect-meta-mask/connect-meta-mask.component';
import { CookieService } from 'ngx-cookie-service';

function _window() {
  return window;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentAddress: any;
  isConnected = false;
  constructor(
    private metamask: ConnetmetamaskService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
    private cookiesService:CookieService
  ) {}

  ngOnInit(): void {
    this.ngxService.start(); 
    let isConnected = sessionStorage.getItem('wallet') > '0' ? true : false;
    if (isConnected) {
      this.currentAddress = localStorage.getItem('address');
      this.isConnected = true;
    }

    this.metamask.init() 
    this.metamask.getWalletObs().subscribe((data: any) => {
      if (data) {
        if (this.metamask.isAddressValid(data)) {
          this.isConnected = true;
          localStorage.setItem('address', data);
          this.currentAddress = data;
          this.ngxService.stop();
        } else {
          this.isConnected = false;
          this.ngxService.stop();
        }
      } else {
        this.ngxService.stop();
      }
    });
  }
  get nativeWindow(): any {
    return _window();
  }
  gotoNftSelect() {
    this.router.navigate(['/nft-select']);
  }
   connetMetamask() {
    this.dialog.open(ConnectMetaMaskComponent, { width: '50%' })
  }

  logOut() {
    this.isConnected = false;
    localStorage.clear();
    sessionStorage.clear();
    this.cookiesService.delete('myNFT')
    // location.reload()
  }
}
