<!-- <ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="medium"
  color="#d71920"
  type="ball-8bits"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
> -->

<div class="start-section  waveWrapper">
  <div class="flex-container text-center hspace ">
    <div>
      <a class="logo-container text-uppercase logo">Ra8bits</a>
      <p class="subtitle text-center text-uppercase">
        breeding <span>room</span>
      </p>
      <div class="flex-col mt-3 text-center">
        <div class="social-icon flex flex-col align-items-center mt30">
          <div class="connect-button">
            <button type="button" *ngIf="!isConnected" class="btn btn-outline-Secondary p-3 text-bold m-b-30 text-uppercase"
              (click)="connetMetamask()">
              Connect Your Wallet
            </button>

          </div>
          <h3 *ngIf="isConnected" class="text-danger" style="color:red">You are connected  With : {{ currentAddress?.substring(0, 4) }}....{{
            currentAddress?.substring(38, 42)
            }}</h3>
          <div class="mb20">
            <button  *ngIf="isConnected" (click)="gotoNftSelect()" type="button"
            class="btn btn-outline-primary btn-outline-primaryp mt-2">
            Start
          </button>

            <button *ngIf="isConnected" (click)="logOut()" type="button"
            class="btn btn-outline-primary btn-outline-primaryp kde">logOut </button>

          </div>
          <div class="text-snoogans">
            <p class="text-uppercase check">Check carefully</p>
          </div>
          <div class="align-items-center hide-text heading var-color mt20  letterspace">
            <h2 class="mb0">NSFW</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
