import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnetmetamaskService } from 'src/app/core/services/metamask/connetmetamask.service';
import { __await } from 'tslib';

@Component({
  selector: 'app-connect-meta-mask',
  templateUrl: './connect-meta-mask.component.html',
  styleUrls: ['./connect-meta-mask.component.scss'],
})
export class ConnectMetaMaskComponent implements OnInit {
  isConnected: boolean = false;
  constructor(
    private metamask: ConnetmetamaskService,
    public dialogRef: MatDialogRef<ConnectMetaMaskComponent>
  ) {}

  ngOnInit(): void {
   
  }

  async connect() {
    let a = await this.metamask.connectAccountMetamask();
    // this.metamask.setWalletObs(a);
    if (a) {
      this.dialogRef.close(true);
    }
  }

  async walletConnect() {
    let a = await this.metamask.walletConnect();
    // this.metamask.setWalletObs(a);
    if (a) {
      this.dialogRef.close(true);
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
