import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ethers } from 'ethers';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
function _window() {
  return window;
}

const tokenAbi = require('../../../../assets/abis/ratbitsToken.json');
const breedingAbi = require('../../../../assets/abis/breeding.json');
const nftAbi = require('../../../../assets/abis/nft.json');

//  Create WalletConnect Provider
const provider = new WalletConnectProvider({
  infuraId: environment.infuraId,
  rpc: {
    [environment.chainId]: environment.rpcUrl,
  },
  chainId: environment.chainId,
});
@Injectable({
  providedIn: 'root',
})
export class ConnetmetamaskService implements OnInit {
  provider: any;
  signer: any;
  isLogedin = new Subject();
  isError = new Subject();
  isErrorWithLink = new Subject();
  address = '';
  private walletDetails$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  tokenContract!: ethers.Contract;
  breedingContract!: ethers.Contract;
  nftContract!: ethers.Contract;
  nftcontractJson: ethers.Contract;
  address01 = '';

  constructor(
    private router: Router,
    private zone: NgZone,
    private dialogRef: MatDialog,
    private toaster: ToastrService
  ) {}
  ngOnInit(): void {}

  get nativeWindow(): any {
    return _window();
  }

  async init() {
    if ((sessionStorage.getItem('wallet') ?? '1') == '1') {
      await this.connectAccountMetamask();
    } else if ((sessionStorage.getItem('wallet') ?? '1') == '2') {
      await this.walletConnect();
    }
  }

  getWalletObs(): Observable<any> {
    return this.walletDetails$.asObservable();
  }

  setWalletObs(profile: any) {
    this.address = profile;
    this.walletDetails$.next(profile);
  }

  successToaster(msg: string) {
    this.toaster.success(msg);
  }

  errorToaster(error: any) {
    this.toaster.error(error?.reason ? error?.reason : error?.message);
    this.dialogRef?.closeAll();
  }
  // alertSuccess(status: boolean, message: any) {
  //   this.isLogedin.next({ status: status, message: message });
  // }

  // alertError(message: any) {
  //   this.isError.next({ message: message });
  // }

  // alertErrorWithLink(message: string, link: string, query: any) {
  //   this.isErrorWithLink.next({ message: message, link: link, query: query });
  // }

  async connectAccountMetamask() {
    if (this.nativeWindow.ethereum !== undefined) {
      await this.nativeWindow.ethereum.request({
        method: 'eth_requestAccounts',
      });

      this.provider = new ethers.providers.Web3Provider(
        this.nativeWindow.ethereum
      );

      this.address = await this.getAccountAddress();
      sessionStorage.setItem('wallet', '1');
      this.nativeWindow.ethereum.on(
        'accountsChanged',
        async (accounts: string[]) => {
          this.address = await this.getAccountAddress();
          if (!this.router.url.includes('home')) {
            this.zone.run(() => {
              this.router.navigate(['/home']);
            });
            location.reload();
          } else {
            location.reload();
          }
        }
      );

      this.nativeWindow.ethereum.on(
        'chainChanged',
        (code: number, reason: string) => {
          if (!this.router.url.includes('home')) {
            this.zone.run(() => {
              this.router.navigate(['/home']);
            });
            location.reload();
          } else {
            location.reload();
          }
        }
      );
    }
    this.nativeWindow.ethereum.on(
      'disconnect',
      (code: number, reason: string) => {
        if (!this.router.url.includes('home')) {
          this.zone.run(() => {
            this.router.navigate(['/home']);
          });
          location.reload();
        } else {
          location.reload();
        }
      }
    );
    return this.address;
  }

  async getAccountAddress() {
    this.signer = this.provider.getSigner();
    var address = await this.signer.getAddress();
    var network = await this.provider.getNetwork();
    localStorage.setItem('address', address);
    let check = localStorage.getItem('address');
    if (check) {
      if (localStorage.getItem('address') !== address) {
        this.errorToaster({message:'Wrong wallet detected'});
      }
    }

    if (network.chainId == environment.chainId) {
      this.tokenContract = new ethers.Contract(
        environment.ra8BitsToken,
        tokenAbi,
        this.signer
      );
      this.breedingContract = new ethers.Contract(
        environment.breedinAddress,
        breedingAbi,
        this.signer
      );
    } else {
      this.errorToaster({message:'Your are on Wrong NetWork'});
      await this.nativeWindow.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${environment.chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
      });
    }
    var data = {
      provider: this.provider,
      signer: this.signer,
      address: address,
      networkId: network,
    };
    this.setWalletObs(address);
    return address;
  }

  async walletConnect() {
    //  Enable session (triggers QR Code modal)
    try {
      await provider.enable().then(
        (e: any) => {
          console.log(e);
        },
        (e: any) => {
          console.log(e);
        }
      );

      this.provider = new ethers.providers.Web3Provider(provider);
      sessionStorage.setItem('wallet', '2');
      this.address = await this.getAccountAddress();
      provider.on('accountsChanged', async (accounts: string[]) => {
        this.address = await this.signer.getAddress();
        if (!this.router.url.includes('home')) {
          this.zone.run(() => {
            this.router.navigate(['/home']);
          });
          location.reload();
        } else {
          location.reload();
        }
      });

      // Subscribe to session disconnection
      provider.on('disconnect', (code: number, reason: string) => {
        location.reload();
      });

      // Subscribe to session disconnection
      provider.on('networkChanged', (code: number, reason: string) => {
        if (!this.router.url.includes('home')) {
          this.zone.run(() => {
            this.router.navigate(['/home']);
          });
          location.reload();
        } else {
          location.reload();
        }
      });
    } catch (error) {}
    return this.address;
  }
  async checkValidBid(_breedId: number, userAddress: string) {
    return await this.breedingContract.checkValidBid(_breedId, userAddress);
  }

  async checkTokenBalance() {
    return await this.tokenContract.balanceOf(this.address);
  }

  async superLikeSign(data) {
    return await this.breedingContract.superLikeNFT(
      data.formNftId,
      data.formNftAddress,
      data.toNftId,
      data.toNftAddress,
      data.toOwnerAddress,
      data.salt,
      data.signature
    );
  }

  async binprogressInfo(breedId) {
    return await this.breedingContract.breedInfoList(breedId);
  }

  async breedInfoList(breedId) {
    return await this.breedingContract.breedInfoList(breedId);
  }
  async tonkenSendForBreeding(breedId) {
    return await this.breedingContract.tonkenSendForBreeding(breedId);
  }
  async SuperlikeDetails(superLikeId) {
    return await this.breedingContract.SuperlikeDetails(superLikeId);
  }
  async waitFprSuperLikeTime() {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let tx = await this.breedingContract.waitFprSuperLikeTime();
        resolve({ hash: tx, status: true });
      } catch (e) {
        console.log(e);
        this.errorToaster(e);
        reject({ hash: e, status: false });
      }
    });
    return promise;
  }

  async signMessage(message: any) {
    var signature = await this.signer.signMessage(message);
    return { status: true, signature };
  }

  async claimDetails(breedId) {
    return await this.breedingContract.claimDetails(breedId);
  }

  isAddressValid(address: any) {
    return ethers.utils.isAddress(address);
  }

  async acceptSuperLike(data) {
    return await this.breedingContract.acceptSuperLike(
      data.formNftId,
      data.formNftAddress,
      data.fromOwnerAddress,
      data.toNftId,
      data.toNftAddress,
      data.salt,
      data.superLikeNFTId,
      data.signature
    );
  }

  async sendTokensForSwiping() {
    return await this.breedingContract.sendTokensForSwiping();
  }

  async rejectSuperLike(data) {
    return await this.breedingContract.rejectSuperLike(
      data.formNftId,
      data.formNftAddress,
      data.fromOwnerAddress,
      data.toNftId,
      data.toNftAddress,
      data.salt,
      data.superLikeNFTId,
      data.signature
    );
  }

  async claimPendingTokens(data) {
    return await this.breedingContract.claimPendingTokensFromSuperLike(
      data.formNftId,
      data.formNftAddress,
      data.toNftId,
      data.toNftAddress,
      data.toOwnerAddress,
      data.salt,
      data.superLikeNFTId,
      data.signature
    );
  }

  async getBreedingCost(
    fromId: number,
    toId: number,
    tier: any,
    isPlaying: boolean
  ) {
    return await this.breedingContract.calculateCost(
      fromId,
      toId,
      tier,
      isPlaying
    );
  }

  async sendToBreedingRoomFirst(
    fromId: number,
    toId: number,
    OwnerOfParrantB,
    tier: any,
    fromAnftAddress,
    fromBnftAddress,
    isPlaying: boolean,
    bias:number[]
  ) {
    return await this.breedingContract.sendToBreedingRoomFirst(
      fromId,
      toId,
      OwnerOfParrantB,
      tier,
      fromAnftAddress,
      fromBnftAddress,
      isPlaying,
      bias
    );
  }

  async calculateCost(
    fromId: number,
    toId: number,
    tier: any,
    isPlaying: boolean
  ) {
    return await this.breedingContract.calculateCost(
      fromId,
      toId,
      tier,
      isPlaying
    );
  }

  async sendSpecialNft(
    _breedId: number,
    specialNft: number,
    specialtANFTAddress: string
  ) {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let tx = await this.breedingContract.sendSpecialNft(
          _breedId,
          specialNft,
          specialtANFTAddress
        );

        resolve({ hash: tx, status: true });
      } catch (e) {
        // this.alertError(e?.message ? e?.message : e?.hash?.reason);
        this.dialogRef.closeAll();
        reject({ hash: e, status: false });
      }
    });
    return promise;
  }

  async TokenListForBreedingInfo(id: any) {
    return await this.breedingContract.TokenListForBreedingInfo(id);
  }

  async sendToBreedingRoomSecond(
    tier: number,
    _breedId: any,
    bias:number[],
    NFTAddress: string,
    isPaying: boolean,

  ) {
    return await this.breedingContract.sendToBreedingRoomSecond(
      tier,
      parseInt(_breedId),
      bias,
      NFTAddress,
      isPaying
    );
  }

  async tokenAmountForSwiping() {
    return await this.breedingContract.tokenAmountForSwiping();
  }

  async claimNft(_breedId: any) {
    return await this.breedingContract.claimNft(_breedId);
  }

  async waitForPartnerTime() {
    return await this.breedingContract.waitForPartnerTime();
  }

  async breedingTime() {
    return await this.breedingContract.breedingTime();
  }

  async withdrawalNft(breedId: any) {
    return await this.breedingContract.withdrawalNft(breedId);
  }

  async getTokenBalance() {
    let tokenContract = new ethers.Contract(
      environment.ra8BitsToken,
      tokenAbi,
      this.signer
    );
    return await tokenContract.balanceOf(this.address);
  }

  async checkApproval(NFTAddress) {
    this.nftContract = new ethers.Contract(NFTAddress, nftAbi, this.signer);
    return await this.nftContract.isApprovedForAll(
      this.address,
      environment.breedinAddress
    );
  }

  async amountForCarrotTier() {
    var promise = new Promise(async (resolve, reject) => {
      try {
        let tx = await this.breedingContract.amountForCarrotTier();
        resolve({ hash: tx, status: true });
      } catch (e) {
        this.errorToaster(e);
        this.dialogRef.closeAll();
        reject({ hash: e, status: false });
      }
    });
    return promise;
  }
  async sendSpecialNftAmount() {
    return await this.breedingContract.sendSpecialNftAmount();
  }

  async approve() {
    return await this.nftContract.setApprovalForAll(
      environment.breedinAddress,
      true
    );
  }

  async getSuperLikeCost() {
    return await this.breedingContract.costForSuperLike();
  }

  async sendTokenToBreeding(breedId) {
    return await this.breedingContract.sendTokenToBreeding(breedId);
  }

  async tierList() {
    return await this.breedingContract.tierList(1);
  }

  async checkAllowance(tokenAddress: any, amount: any) {
    let tokenContract = new ethers.Contract(
      tokenAddress,
      tokenAbi,
      this.signer
    );
    let allowanceAmount = await tokenContract.allowance(
      this.address,
      environment.breedinAddress
    );
    return parseInt(allowanceAmount._hex, 16) >= parseInt(amount._hex, 16);
  }

  async approveToken(amount: any, tokenAddress: any) {
    let tokenContract = new ethers.Contract(
      tokenAddress,
      tokenAbi,
      this.signer
    );
    return await tokenContract.approve(
      environment.breedinAddress,
      amount.toString()
    );
  }

  async specialDetails(breedId) {
    return await this.breedingContract.specialDetails(breedId);
  }

  async nftContractObj(contractAddress, nftid) {
    this.nftcontractJson = new ethers.Contract(
      contractAddress,
      nftAbi,
      this.signer
    );
    return await this.nftcontractJson.uri(nftid);
  }

  async checkNftUnderBreeding(NFTAddress, nftid) {
    this.nftContract = new ethers.Contract(NFTAddress, nftAbi, this.signer);
    let res = await this.nftContract.balanceOf(
      environment.breedinAddress,
      nftid
    );
    return parseInt(res._hex, 16) > 0 ? true : false;
  }
}
