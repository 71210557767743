import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpErrorHandlerService } from '../services/http-error-handler.service';
// import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private errorHandler :HttpErrorHandlerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });
    request = request.clone({
      headers: request.headers.set('charset', 'UTF-8'),
    });

    request = request.clone({
      headers: request.headers.set('Access-Control-Allow-Origin', '*'),
    });
    request = request.clone({
      headers: request.headers.set('X-API-Key',
        'hq6rjDvR47cpC9enmXcjpEBXysAwXJAxsQG3A0JbUYlBVAfnnUFfkdEY2OOoErju'),
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error)
        return throwError(error);
      })
      ,
      finalize(() => {
      })
    );
}
}
