// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  chainId: 97,
  providerTestNetURL: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  providerMainNetURL: 'https://bsc-dataseed1.binance.org',
  // baseURL:'https://tronwing.io/ratbits/api/',
  baseURL:'http://codetentacles-006-site36.htempurl.com/api/',
  breedinAddress: '0xB69Ad558f765D95D128E306fC02b4699E16CB07a',
  ra8BitsToken: '0xae14cdd3824229a57902eeda44106fc7a3b272e9',
  ownerAddress: '0x350F84C2f5272973646342Be1AdbE232324A552E',
  ContactAddress: [
    '0x774A38783aD18D8b5a38d976e89fE634BeB4A841',
    '0x719E5Afb17Ed0d83DDB8F5dD82F9c6c1D2f3B521',
    '0xd07096a43358d7b685945b31d59c91ab558c2b4a'
  ],

  buyNft:'http://codetentacles-006-site49.htempurl.com/artist/0x36441737072ABb94707ef09c82342550DAfF5803',
  waitingTime: 30000,
  defaultImage: 'assets/rabitloader.gif',
  firebase: {
    apiKey: 'AIzaSyAte9kufWCQwi45NPeKnyIuTo9MGBehbQU',
    authDomain: 'breeding-room.firebaseapp.com',
    projectId: 'breeding-room',
    storageBucket: 'breeding-room.appspot.com',
    messagingSenderId: '274673048910',
    appId: '1:274673048910:web:92146031f8a078199a521f',
    measurementId: 'G-XRHHLCB5EN',
    vapidKey:
      'BF3uIOq63YWgSJjm-OfAVtCL7iLUcuGUz_oruyLr2GsiqQro9cn98BXIIdxTIVp4SgFCoXUeXp9lKruwGxAvTLs',
  },
  infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
  rpcUrl :"https://data-seed-prebsc-1-s1.binance.org:8545"
};
// 0xae14cdd3824229a57902eeda44106fc7a3b272e9
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
