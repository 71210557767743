<!-- <div class="text-center ">
    <div class="">
        <div class="text-sm-end">
            <p class="text-white text-foot text-uppercase"> ra8bits</p>
        </div>
    </div>
</div> -->

<!-- <footer>
    <div class="copy-right-area">
        <div class="copyright">           
            <div class="copyright-heading">
                <p class="text-uppercase text-white">
                    © 2021 by ra8bits. All Rights Reserved
                </p>
            </div>
        </div>
    </div>
</footer> -->
<div class="footer" >
<div class="text-center ">
    <div class="">
        <div class="text-sm-end">
            <!-- <p class="text-white text-foot text-uppercase"> ra8bits</p> -->
            <a
            _ngcontent-mwm-c129=""
            class="flex-center"
            ng-reflect-router-link=""
            href="https://moonboxes.io/"
            target="_blank"
            ><video _ngcontent-mwm-c129="" autoplay="" class="animated-logo" loop="" style="width:35px">
              <source
                _ngcontent-mwm-c129=""
                src="assets/img/MoonBoxes_LOGO_icon_animated.webm"
                type="video/mp4"
              /></video
            ><svg
              _ngcontent-mwm-c129=""
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1782.14 174.96"
              style="height: 40px; width: 200px"
            >
              <defs _ngcontent-mwm-c129="">
                <style _ngcontent-mwm-c129="">
                  .cls-1 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <g _ngcontent-mwm-c129="" id="Layer_2" data-name="Layer 2">
                <g _ngcontent-mwm-c129="" id="Layer_1-2" data-name="Layer 1">
                  <path
                    _ngcontent-mwm-c129=""
                    d="M24.12,209V34H63l59.29,70.71L181.34,34h39.12V209H182.55V91.61l-60.26,71.93L61.78,91.85V209Z"
                    transform="translate(-24.12 -34.02)"
                    class="cls-1"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M285.1,209a36.09,36.09,0,0,1-36-36V70A34.65,34.65,0,0,1,259.7,44.59,34.62,34.62,0,0,1,285.1,34h103A34.74,34.74,0,0,1,413.4,44.59,34.42,34.42,0,0,1,424.09,70V173a36.3,36.3,0,0,1-36,36Zm9.72-37.91h91.12V71.93H286.8v99.14Z"
                    transform="translate(-24.12 -34.02)"
                    class="cls-1"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M486.3,209a36.09,36.09,0,0,1-36-36V70a34.62,34.62,0,0,1,10.57-25.39A34.6,34.6,0,0,1,486.3,34h103A34.74,34.74,0,0,1,614.6,44.59,34.43,34.43,0,0,1,625.3,70V173a36.32,36.32,0,0,1-36,36ZM496,171.07h91.12V71.93H488v99.14Z"
                    transform="translate(-24.12 -34.02)"
                    class="cls-1"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M652,209V34H690.9l97.93,116.64V34H827V209H788.1L689.69,91.85V209Z"
                    transform="translate(-24.12 -34.02)"
                    class="cls-1"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M854.92,209V34H991a31.51,31.51,0,0,1,31.59,31.59v35.72a33.73,33.73,0,0,1-.6,6.32,20.11,20.11,0,0,1-2.07,5.83,69.33,69.33,0,0,1,7.41,12.76,30.32,30.32,0,0,1,2.55,12v39.12a30.29,30.29,0,0,1-9.35,22.36A30.88,30.88,0,0,1,998.05,209Zm35.24-102.3h97.2q5.34,0,7-1.83t1.7-7.16V69.25q0-5.34-1.7-7.16t-7-1.83h-97.2c-3.57,0-5.95.61-7.17,1.83s-1.82,3.6-1.82,7.16V97.69q0,5.34,1.82,7.16C884.21,106.07,886.59,106.68,890.16,106.68Zm0,76.06H994.41q5.58,0,7.29-1.83t1.7-7.16V141.91q0-5.34-1.7-7.17t-7.29-1.82H890.16c-3.57,0-5.95.61-7.17,1.82s-1.82,3.61-1.82,7.17v31.84q0,5.34,1.82,7.16C884.21,182.13,886.59,182.74,890.16,182.74Z"
                    transform="translate(-24.12 -34.02)"class="cls-2"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M1087.47,209a31.53,31.53,0,0,1-31.59-31.59V65.61A31.53,31.53,0,0,1,1087.47,34h111.78a30.6,30.6,0,0,1,22.24,9.23,30.29,30.29,0,0,1,9.35,22.36V177.39a30.29,30.29,0,0,1-9.35,22.36,30.6,30.6,0,0,1-22.24,9.23Zm3.65-26.24h104.24q5.6,0,7.29-1.83c1.14-1.21,1.7-3.6,1.7-7.16V69.25c0-3.56-.56-6-1.7-7.16s-3.56-1.83-7.29-1.83H1091.12q-5.36,0-7.17,1.83c-1.22,1.21-1.82,3.6-1.82,7.16v104.5c0,3.56.6,5.95,1.82,7.16S1087.55,182.74,1091.12,182.74Z"
                    transform="translate(-24.12 -34.02)" class="cls-2"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M1246.39,209v-4.86l69.5-82.62-69.5-82.62V34h30.38l56.37,66.83L1389.28,34h30.37v4.86l-69.5,82.62,69.74,82.62V209h-30.61l-56.38-66.83L1276.77,209Z"
                    transform="translate(-24.12 -34.02)" class="cls-2"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M1446.62,209V34H1607V60.26H1473.11v48.12h107.65v26.24H1473.11v48.12H1607V209Z"
                    transform="translate(-24.12 -34.02)" class="cls-2"
                  ></path>
                  <path
                    _ngcontent-mwm-c129=""
                    d="M1662.89,209a31.53,31.53,0,0,1-31.59-31.59V167.18h26.24v6.57q0,5.34,1.83,7.16t7.17,1.83H1771q5.34,0,7-1.83t1.7-7.16V143.61q0-5.58-1.7-7.29t-7-1.7H1662.89A31.53,31.53,0,0,1,1631.3,103V65.61A31.53,31.53,0,0,1,1662.89,34h111.78a31.51,31.51,0,0,1,31.59,31.59V75.82h-26.49V69.25q0-5.34-1.7-7.16t-7-1.83H1666.54q-5.36,0-7.17,1.83t-1.83,7.16V99.39q0,5.6,1.83,7.29t7.17,1.7h108.13A31.51,31.51,0,0,1,1806.26,140v37.42A31.51,31.51,0,0,1,1774.67,209Z"
                    transform="translate(-24.12 -34.02)" class="cls-2"
                  ></path>
                </g>
              </g>
              </svg>
          </a>
      
     
        </div>
    </div>
</div>

    <div class="copy-right-area">
        <p class="mb-0"> © 2022 by <a [routerLink]="['/home']" target="_blank">Moonshot ra8bits</a>. All Rights Reserved</p>
    </div>
</div>