<app-header></app-header>

<router-outlet>
    <section class="min-ht"></section>
</router-outlet>

<app-footer></app-footer>
<!-- <div id="successMsg"  *ngIf="showSuccessDialog">
    <p><i class="fa fa-check-square-o icon-size"></i> {{successMessage}}</p>
</div>
<div id="error" *ngIf="errorMessagedialog">
    <p><i class="fa fa-close  icon-size"></i>{{errorMessage}}</p>
</div>
<div id="error" *ngIf="errorMessagedialogLink">
    <p><i class="fa fa-close  icon-size"></i>{{errorMessageLink}} <a style="text-decoration: underline;" [routerLink]="linkMessage" [queryParams]="queryparams"><b>goto breeding room</b></a></p>
</div> -->
    
<ngx-ui-loader ></ngx-ui-loader>

