import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  constructor(private ms:AngularFireMessaging,private toster:ToastrService) { }

  requestPermission(){
    this.ms.requestPermission.subscribe({next:(token)=>{
      if(token=='granted'){
        this.ms.getToken.subscribe(r=>{
          localStorage.setItem("firebaseTokenId",r)
        })
      }else{
        const firebaseTokenId =localStorage.getItem("firebaseTokenId")
        if(firebaseTokenId){
          localStorage.removeItem("firebaseTokenId")
        }
      }
    }})
  }

  getMessage(){
    this.ms.messages.subscribe({next:(payload)=>{
    //  let  options: NotificationOptions = {
    //   body:payload.notification.body,
    //  }

    //  this.toster.success(payload.notification.title)
    //   let notify:Notification = new Notification(payload.notification.title,options)
    //   notify.onclick=e=>{
    //    window.open(`${payload.data.link}`,'_blank')
    //   }
    }})
  }
}
