<div class="nav-wrapper">
  <div _ngcontent-mwm-c129="" class="logo-container flex border-0">
    <a
      _ngcontent-mwm-c129=""
      class="flex-center"
      [routerLink]="['/home']"
      >
     <img src="assets/Ra8bitHole_Logo2.png" alt="" height="30px" width="300px">
    </a>
  </div>
  <nav class="dflex">
    <input class="hidden" type="checkbox" id="menuToggle" />
    <label class="menu-btn" (click)="toggle()" htmlFor="menuToggle">
      <div class="menu"></div>
      <div class="menu"></div>
      <div class="menu"></div>
    </label>
    <div class="nav-container" [ngClass]="{ flex: toggleMenu }">
      <ul class="nav-tabs">
        <ng-container *ngIf="storage.isProfileSetup">
          <li class="nav-tab" routerLink="/select-breed">
            <div class="nav-link text-uppercase">Explore</div>
          </li>
          <div class="vi"></div>
          <li class="nav-tab" routerLink="/nft-select">
            <div class="nav-link text-uppercase">My NFT's</div>
          </li>
          <div class="vi"></div>
          <li class="nav-tab" [routerLink]="['match']">
            <div class="nav-link text-uppercase">My Matches</div>
          </li>
          <div class="vi"></div>
          <li class="nav-tab" [routerLink]="['superLike']"  [queryParams]="{tab:0}">
            <div class="nav-link text-uppercase">My SuperLikes</div>
          </li>
          <div class="vi"></div>
          <li class="nav-tab" (click)="gotoBreedingRoom()">
            <div class="nav-link text-uppercase">Breeding Room</div>
          </li>
          <div class="vi"></div>
        </ng-container>
        <li class="nav-tab">
          <a [href]="buyNft" target="_blank">
            <div class="nav-link text-uppercase">Buy Nft's</div>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="brand-name">
    <div class="nav-tab">
      <div class="nav-link text-uppercase rl">Ra8bits</div>
    </div>
  </div>
  
</div>

<!-- 
    <div class="topnav">
      <a href="#home" class="active">Logo</a>
      <div id="myLinks">
        <a href="#news">News</a>
        <a href="#contact">Contact</a>
        <a href="#about">About</a>
      </div>
      <a href="javascript:void(0);" class="icon" onclick="myFunction()">
        <i class="fa fa-bars"></i>
      </a>
    </div> -->
