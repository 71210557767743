import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './layout/home/home.component';
import { SwiperModule } from 'swiper/angular';
import { ConnetmetamaskService } from './core/services/metamask/connetmetamask.service';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ConnectMetaMaskComponent } from './layout/connect-meta-mask/connect-meta-mask.component';
import { environment } from 'src/environments/environment';
import{AngularFireMessagingModule}from'@angular/fire/compat/messaging';
import{AngularFireModule}from'@angular/fire/compat';
import { MessagingService } from './core/services/messaging/messaging.service';
import { CookieService } from 'ngx-cookie-service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

// initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ConnectMetaMaskComponent,
    PageNotFoundComponent,
 
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SwiperModule,
    HttpClientModule,
    FormsModule,
    HammerModule,
    LazyLoadImageModule,
    AngularFireMessagingModule,     
    AngularFireModule.initializeApp(environment.firebase), 
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot({"fgsColor":'red',"pbColor": "red",}),
    // NgxUiLoaderHttpModule.forRoot({ showForeground: true })

  ],
  providers: [ConnetmetamaskService,MessagingService,CookieService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
