import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/dataservice/local-storage.service';
import { ConnetmetamaskService } from 'src/app/core/services/metamask/connetmetamask.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentNftDetais: any;
  toggleMenu = false;

  constructor(private router: Router, public storage: LocalStorageService) { }
  buyNft = environment.buyNft;
  
  ngOnInit(): void {
    const myNFT = this.storage.retrieve('myNFT')
    if (myNFT) {
      this.currentNftDetais = JSON.parse(this.storage.retrieve('myNFT'));
    }
  }
  editProfile(){
    this.router.navigate(['/nft-detail'])
  }
  gotoBreedingRoom() {
    const myNFT =  this.storage.retrieve('myNFT')
    if (myNFT) {
      this.currentNftDetais = JSON.parse(this.storage.retrieve('myNFT'));
    }
    this.router.navigate(['b-inprogress-list'], { queryParams: {tab:0, nftid: this.currentNftDetais.nftid } })
  }

  toggle() {
    this.toggleMenu = !this.toggleMenu;
  }

}
