import { Component, OnInit } from '@angular/core';
import { ConnetmetamaskService } from './core/services/metamask/connetmetamask.service';
import { MessagingService } from './core/services/messaging/messaging.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Ra8bitsBreed';
  // showSuccessDialog :boolean = false;
  // successMessage :any ='';
  // errorMessage :any = '';
  // errorMessagedialog : boolean = false;
  // errorMessagedialogLink: boolean = false;
  // errorMessageLink: string;
  // linkMessage: any;
  // queryparams: any;

  constructor(private metamask:ConnetmetamaskService,private ms:MessagingService){
    // metamask.isLogedin.subscribe(
    //   (data :any)=>{
    //     if(data.status){
    //       this.showSuccessDialog = true;
    //       this.successMessage = data.message;
    //       setTimeout(()=>{this.showSuccessDialog = false},4000);
    //     }

    //   }
    // );
    
    //   metamask.isError.subscribe(
    //     (res:any)=>{
    //       this.errorMessagedialog = true;
    //       this.errorMessage = res.message;
    //       setTimeout(()=>{this.errorMessagedialog = false},4000);
    //     }
    //   )
    //   metamask.isErrorWithLink.subscribe(
    //     (res:any)=>{
    //       this.linkMessage = res.link
    //       this.queryparams = res.query
    //       this.errorMessagedialogLink = true;
    //       this.errorMessageLink = res.message;
    //       setTimeout(()=>{this.errorMessagedialogLink = false},4000);
    //     }
    //   )

  }
  ngOnInit(): void {
    this.ms.requestPermission()
    this.ms.getMessage()
  }
}
