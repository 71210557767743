import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private cookieService: CookieService) { }

  store(key:string,content:any) {
    this.cookieService.set(key, content);
  }

  retrieve(key:string) {
    let storedKey = this.cookieService.get(key);
    return storedKey;
  }

  remove(key:string){
    this.cookieService.delete(key);
  }
  
  get isProfileSetup(): boolean {
    let auth = this.cookieService.get('myNFT');
    return auth == "" ? false : true;
  }
}
